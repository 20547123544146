.admin-container {
  padding: 20px;
  background-color: #f9f9f9;

  .section {
    margin-bottom: 30px;
    padding: 15px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

    h2 {
      margin-bottom: 15px;
      font-size: 1.5rem;
      color: #333;
    }

    textarea {
      display: block;
      width: calc(100% - 20px);
      padding: 10px;
      margin: 10px 0;
      border: 1px solid #ddd;
      border-radius: 4px;
      font-size: 1rem;
      box-sizing: border-box;
      resize: none;
      overflow: auto;
      max-height: 200px;

      &.auto-height {
        overflow: hidden;
      }

      &:focus {
        outline: none;
        border-color: #007bff;
        box-shadow: 0 0 0 2px rgba(38, 143, 255, 0.2);
      }
    }

    input[type="text"],
    input[type="number"] {
      display: block;
      width: calc(100% - 20px);
      padding: 10px;
      margin: 10px 0;
      border: 1px solid #ddd;
      border-radius: 4px;
      font-size: 1rem;
      box-sizing: border-box;
    }

    button {
      padding: 10px 15px;
      border: none;
      border-radius: 4px;
      background-color: #007bff;
      color: #fff;
      font-size: 1rem;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: #0056b3;
      }
    }

    .news-list {
      margin-bottom: 15px;

      .news-item {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        margin-bottom: 5px;
        background-color: #f1f1f1;
        border-radius: 4px;
        align-items: center;

        span {
          max-width: 90%;
        }

        button {
          height: 2.5rem;
          background-color: #dc3545;

          &:hover {
            background-color: #c82333;
          }
        }
      }
    }

    .text-weights {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;

      div {
        flex: 1;
        display: flex;
        flex-direction: column;
        margin-right: 10px;

        &:last-child {
          margin-right: 0;
        }
      }

      textarea {
        overflow: hidden;
      }
    }
  }
}
