// JusticeBalance.scss
.main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.balance-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 650px;
    width: 100%;
    overflow: hidden;
    position: relative;
  }
  
  .balance {
    position: relative;
    width: 600px;
    height: 400px;
  }
  
  .balance-pole {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
  }
  
  .arms {
    position: absolute;
    top: -5%;
    left: 0;
    transform-origin: center top;
    width: 100%;
  }
  
  .balance-arms {
    width: 100%;
    height: auto;
  }
  
  .left-platform, .right-platform {
    position: absolute;
    bottom: 0;
    width: 200px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 30%;
  }
  
  .left-platform {
    left: -15%;
  }
  
  .right-platform {
    right: -15%;
  }
  
  .balance-scale {
    width: 100%;
    height: auto;
  }
  
  .left-text, .right-text {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    position: absolute;
    bottom: -50px;
  }

  .images-container {
    display: flex;
    flex-direction: row;
    margin-top: -150px;
  }
  
  .balance-handshake, .balance-bible {
    width: 200px;
    height: 200px;
    margin-inline: 200px;
  }
  