.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f4f4f9;
  
    h2 {
      margin-bottom: 20px;
      font-size: 2rem;
      color: #333;
    }
  
    .input-group {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 400px;
      align-items: center;
  
      input {
        width: 70%;
        padding: 10px;
        margin-bottom: 15px;
        border: 1px solid #ccc;
        font-size: 1rem;
        outline: none;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
        &:focus {
          border-color: #007bff;
          box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
        }
      }
  
      button {
        width: 35%;
        padding: 10px;
        border: none;
        background-color: #007bff;
        color: #fff;
        font-size: 1rem;
        cursor: pointer;
        transition: background-color 0.3s ease;
  
        &:hover {
          background-color: #0056b3;
        }
      }
    }
  }
  