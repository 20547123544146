.scrollable-rectangle {
    margin-top: 1rem;
    background-color: #f8f8f8;
    overflow-y: auto;
    border: 1px;
    border-radius: 20px;
    padding: 10px;
    text-align: center;
  }
  
  .content {
    white-space: pre-wrap;
  }
  