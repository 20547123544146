.custom-editor {
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin: 10px 0;
  padding: 10px;
  position: relative;
}

.custom-editor .rsw-toolbar {
  border-bottom: 1px solid #ddd;
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: #f9f9f9;
  margin-bottom: 10px;
}

.custom-editor .rsw-ce {
  min-height: 75px;
  max-height: 300px;
  overflow-y: auto;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  line-height: 1.5;
  box-sizing: border-box;
  position: relative;
}

.custom-editor .rsw-ce:empty::before {
  content: attr(data-placeholder);
  color: #999;
  font-style: italic;
  position: absolute;
  top: 10px;
  left: 10px;
}

.rsw-btn {
  background: transparent !important;
  border: 0 !important;
  cursor: pointer !important;
  color: #222 !important;
  font-size: 1em !important;
  height: 2em !important;
  outline: none !important;
  padding: 0 !important;
  width: 2em !important;
}

.rsw-btn:hover {
  background: #eaeaea !important;
}

.rsw-btn[data-active='true'] {
  background: #e0e0e0 !important;
}

.rsw-toolbar {
  align-items: center !important;
  background-color: #f5f5f5 !important;
  border-bottom: 1px solid #ddd !important;
  display: flex !important;
}
